import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import LoginScreen from "../containers/Login/Login_Screen";
import HomeScreen from "../containers/Home/Home_Screen";

const Router_Screen = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <LoginScreen />
                </Route>
                <Route path="/home">
                    <HomeScreen />
                </Route>
            </Switch>
        </Router>
    )
}
export default Router_Screen;