import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import "../../types/home.css";
import ChartDoanhThu from "../../components/chart/chart_doanhthu";
import { Layout, Select } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import MenuLeft from '../../components/menu/menu_left';

const { Option } = Select;
const { Header, Content, Footer, Sider } = Layout;
const data_timeline = [
    { id: 1, content: 'Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure' },
    { id: 2, content: 'Outlines keep you honest. And keep structure' },
    { id: 3, content: 'Outlines keep you honest. And keep structure' },
    { id: 4, content: 'Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure' },
    { id: 5, content: 'Outlines keep you honest. And keep structure' },
    { id: 6, content: 'Outlines keep you honest. And keep structure' },
    { id: 7, content: 'Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure' },
    { id: 8, content: 'Outlines keep you honest. And keep structure' },
    { id: 9, content: 'Outlines keep you honest. And keep structure' },
    { id: 10, content: 'Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure' },
    { id: 11, content: 'Outlines keep you honest. And keep structure' },
    { id: 12, content: 'Outlines keep you honest. And keep structure' },
    { id: 13, content: 'Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure Outlines keep you honest. And keep structure' },
    { id: 14, content: 'Outlines keep you honest. And keep structure' },
    { id: 15, content: 'Outlines keep you honest. And keep structure' },
];

const HomeScreen = () => {
    const [number, set_number] = useState("80");
    const [collapsed, set_collapsed] = useState(false);

    return (
        <Layout className="home_body">
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onCollapse={(collapsed) => set_collapsed(collapsed)}
                style={{ height: "100vh" }}
                breakpoint="lg"
                collapsedWidth={number}
                onBreakpoint={(broken) => {
                    if (broken === true) {
                        set_number("0");
                    } else {
                        set_number("80");
                    }
                }}
            ></Sider>
            <MenuLeft />
            <Layout>
                <Header style={{ padding: 0, backgroundColor: "white" }}>
                    <div className="trigger" onClick={() => set_collapsed(!collapsed)}>
                        {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                    </div>
                </Header>
                <Content>
                    <div className="view_content">
                        <div className="ant-row">
                            <div style={{ padding: 8 }} className="ant-col ant-col-xs-24 ant-col-xl-17">
                                <div className="content_item">
                                    <div className="title_content ant-row">
                                        <div className="ant-col ant-col-xs-12 ant-col-xl-12">
                                            <h1>Doanh thu</h1>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }} className="ant-col ant-col-xs-12 ant-col-xl-12">
                                            <Select className="combobox" defaultValue="jack" >
                                                <Option value="jack">7 ngày qua</Option>
                                                <Option value="lucy">Tháng này</Option>
                                                <Option value="disabled" >
                                                    Tháng trước
                                                </Option>
                                                <Option value="Yiminghe">Ngày khác</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="ant-row">
                                        <div style={{ padding: 8 }} className=" ant-col ant-col-xs-24 ant-col-xl-8">
                                            <div className="item_total" style={{ backgroundColor: "#e1f0ff" }}>
                                                <span style={{ color: "#3699ff" }}>Doanh Thu</span>
                                                <h1 style={{ color: "#3699ff" }}>500,000 VND</h1>
                                            </div>
                                        </div>
                                        <div style={{ padding: 8 }} className=" ant-col ant-col-xs-24 ant-col-xl-8">
                                            <div className="item_total" style={{ backgroundColor: "#c9f7f5" }}>
                                                <span style={{ color: "#1bc5bd" }}>Tổng đơn</span>
                                                <h1 style={{ color: "#1bc5bd" }}>500,000 VND</h1>
                                            </div>
                                        </div>
                                        <div style={{ padding: 8 }} className=" ant-col ant-col-xs-24 ant-col-xl-8">
                                            <div className="item_total" style={{ backgroundColor: "#ffe2e5" }}>
                                                <span style={{ color: "#f64e60" }}>Sản phẩm</span>
                                                <h1 style={{ color: "#f64e60" }}>500,000 VND</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{padding:8}} className="ant-row">
                                        <div className="title_content ant-col ant-col-xs-24 ant-col-xl-24">
                                            <h1>Biểu đồ thu nhập</h1>
                                        </div>
                                        <div className="view_char ant-col ant-col-xs-24 ant-col-xl-24">
                                            <ChartDoanhThu />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: 8 }} className="ant-col ant-col-xs-24 ant-col-xl-7">
                                <div className="content_item">
                                    <div className="title_content">
                                        <h1>Hoạt động</h1>
                                        <Select className="combobox" defaultValue="jack" style={{ width: 250, outline: "none" }} >
                                            <Option value="jack">7 ngày qua</Option>
                                            <Option value="lucy">Tháng này</Option>
                                            <Option value="disabled" >
                                                Tháng trước
                                            </Option>
                                            <Option value="Yiminghe">Ngày khác</Option>
                                        </Select>
                                    </div>
                                    <div className="overflow">
                                        {data_timeline.map((item) => {
                                            return (
                                                <div key={item.id} className="view_item_timeline">
                                                    <div className="time">
                                                        15:20
                                                    </div>
                                                    <div>
                                                        <div style={{ borderColor: 'red' }} className="timeline_dot">
                                                        </div>
                                                        <div className="content_timeline">
                                                            {item.content}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Design ©2021 Created by Seo</Footer>
            </Layout>
        </Layout>
    )
}
export default HomeScreen;