import React, { useState } from 'react';
import { Redirect} from "react-router-dom";
import '../../types/login.css';

import image_login from '../../assets/images/image_login.png';
import image_show_password from '../../assets/icons/icons_invisible.png';
import image_hiden_password from '../../assets/icons/icons_eye.png';

const LoginScreen = () => {
    const [show_password, set_show_password] = useState(false);
    const [check_login, set_check_login] = useState(false);


    return (
        <div className="login_body">
            {check_login === true ? <Redirect to="/home" />
                :
                <div className="login_view_login" >
                    <div className="login_view_left">
                        <img className="login_image" src={image_login} alt="Logo" />
                    </div>
                    <div className="login_view_right">
                        <a href="null" className="login_text_login">Đăng  Nhập</a>
                        <div style={{ width: "80%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <div className="login_view_input" >
                                <input placeholder="Tên đăng nhập">
                                </input>
                            </div>
                            <div className="login_view_input">
                                <input type={show_password === false ? "password" : "default"} placeholder="Mật khẩu">

                                </input>
                                <img alt="showpassword"
                                    onClick={() => set_show_password(!show_password)}
                                    style={{ height: 20, width: 20, color: "red" }}
                                    src={show_password === false ? image_show_password : image_hiden_password} />
                            </div>

                            <button onClick={() => set_check_login(true)} className="button_login">
                                <h1  href='null' >Đăng nhập</h1>
                            </button>

                        </div>
                    </div>
                </div>
            }
        </div>


    );

}
export default LoginScreen;