import React, { useState } from "react";
import "../../types/menu_left.css";
import { Layout, Menu, } from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
    FileOutlined,
    TeamOutlined,
    UserOutlined,
    VideoCameraOutlined,
  } from '@ant-design/icons';

const { Sider } = Layout;
const MenuLeft = () => {
    const [number, set_number] = useState("80");
    const [collapsed, set_collapsed] = useState(false);
    return (
        <Sider
            className="overflow height"
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={false}
            style={{ position: "fixed", left: 0 }}
            breakpoint="lg"
            collapsedWidth={number}
            onBreakpoint={(broken) => {
                if (broken === true) {
                    set_number("0");
                } else {
                    set_number("80");
                }
            }}
            onCollapse={(collapsed, type) => {
                set_collapsed(collapsed)
            }}
        >
            <div className="logo" />
            <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={["1"]}>

                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="1"
                    icon={<DesktopOutlined />}>
                    Tổng quan

                </Menu.Item>
                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="3"
                    icon={<PieChartOutlined />}>

                    Thực đơn
                </Menu.Item>
                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="4"
                    icon={<FileOutlined />}>
                    Hoá đơn

                </Menu.Item>

                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="5"
                    icon={<TeamOutlined />}>

                    Nhập hàng
                </Menu.Item>
                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="6"
                    icon={<UserOutlined />}>
                    Kho
                </Menu.Item>
                <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="7"
                    icon={<VideoCameraOutlined />}>
                    Nhân viên
                </Menu.Item>
            </Menu>
        </Sider>


    );
}
export default MenuLeft;